import styled from 'styled-components'

const PretSmartphoneContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;

  & > .VideoPlayer {
    width: 100%;
    max-width: calc(693px + 2 * 23px);
    padding: 0 23px;
    margin-bottom: 15px;
  }
`

export default PretSmartphoneContainer
